import { initializeApp } from 'firebase/app';
import { getAuth } from 'firebase/auth';
import { getFirestore } from 'firebase/firestore';

// Your Firebase config object, which you'd get from your Firebase project settings.
const firebaseConfig = {
    apiKey: "AIzaSyC1SIppeoMguDT711E4oWXDSuyp_r_PWRk",
    authDomain: "docai-e8636.firebaseapp.com",
    projectId: "docai-e8636",
    storageBucket: "docai-e8636.appspot.com",
    messagingSenderId: "62616855514",
    appId: "1:62616855514:web:42d3d6270aed959e0416e3",
    measurementId: "G-C8TCH832YJ"
};

const app = initializeApp(firebaseConfig);
const db = getFirestore(app);
const auth = getAuth(app);

export { auth, app, db };
