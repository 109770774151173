

import Navbar from './components/Navbar';
import Protected from './components/Protected';
import { AuthContextProvider } from './context/AuthContext';
import Account from './pages/Account';
import Home from './pages/Home';
import Signin from './pages/Signin';


import React, { useState, useEffect } from 'react';

import Sidebar from './components/Sidebar';
import Collections from './components/Collections';
import SignInWithEmail from './components/SignInWithEmail';
import {
  CollectionContext,
  MentionsListDataContext,
  CategoriesListDataContext,
  TranslateListDataContext
} from './context/DataContexts';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import { auth } from './firebase';
import { Container, Row, Col, Card, Form, Button, Alert } from 'react-bootstrap';

import 'bootswatch/dist/minty/bootstrap.min.css'; // Added this :boom:
import './App.css';
function App() {

  const [currentUser, setCurrentUser] = useState(null);
  const [loading, setLoading] = useState(true);
  const [selectedICollection, setSelectedICollection] = useState(null);

  const [mentionsListDataContext, setMentionsListDataContext] = useState(null);
  const [categoriesListDataContext, setCategoriesListDataContext] = useState(null);
  const [translateListDataContext, setTranslateListDataContext] = useState(null);

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged(user => {
      setCurrentUser(user);
      setLoading(false);
    });

    // Cleanup subscription on unmount
    return () => unsubscribe();
  }, []);
  return (

    <AuthContextProvider>
      <CollectionContext.Provider value={{ selectedICollection, setSelectedICollection }}>
        <MentionsListDataContext.Provider value={{ mentionsListDataContext, setMentionsListDataContext }}>
          <CategoriesListDataContext.Provider value={{ categoriesListDataContext, setCategoriesListDataContext }}>
            <TranslateListDataContext.Provider value={{ translateListDataContext, setTranslateListDataContext }}>
              <Navbar />

              <Routes>

                <Route path='/' element={<Home />} />
                <Route path='/signin' element={<Signin />} />
                <Route
                  path='/account'
                  element={
                    <Protected>
                      {/* <Container className='full-width'  >
                        <Row>
                          <Col md="3" lg="2">
                            <Sidebar currentUser={currentUser} />
                          </Col>
                          <Col md="9" lg="10">
                            <Collections />
                          </Col>
                        </Row>
                      </Container> */}


                      <div className="container-fluid">

                        <div id="sidebar" className="col-md-3 col-lg-2 d-md-block bg-light sidebar collapse">

                          <Sidebar currentUser={currentUser} />
                        </div>

                        <main id="main-content" role="main" className="col-md-9 ml-sm-auto col-lg-10 ">


                          <Collections />

                        </main>

                      </div>
                    </Protected>
                  }
                />

              </Routes>

            </TranslateListDataContext.Provider>
          </CategoriesListDataContext.Provider>
        </MentionsListDataContext.Provider>
      </CollectionContext.Provider>
    </AuthContextProvider >

  );
}

export default App;
