// import React, { useContext } from 'react';
// import {
//     CollectionContext,
// } from '../context/DataContexts';
// import MyDropzone from './MyDropzone';
// import Files from './files';
// import { UserAuth } from '../context/AuthContext';
// function Collections() {
//     const { user, logOut, userData } = UserAuth();
//     const { selectedICollection } = useContext(CollectionContext);

//     if (!selectedICollection) {
//         return (
//             <div style={{
//                 display: 'flex',
//                 justifyContent: 'center',
//                 alignItems: 'center',
//                 height: '90vh',  // This will ensure it covers the full viewport height
//                 fontSize: '24px'  // Just an example, adjust as needed
//             }}>
//                 Please select a folder from the sidebar.
//             </div>
//         );
//     }




//     return (
//         <>
//             <div className="">
//                 <div className='pt-4 position-relative mt-4'>
//                     <h6 className='text-uppercase  mt-3  '>
//                         <i className="fas fa-folder me-2 ml-3 "></i>
//                         {selectedICollection.collectionName}


//                     </h6>
//                     <div className="features-list d-flex justify-content-start " style={{ top: '18px', right: '5px' }}>
//                         {selectedICollection?.autoCategoryEnabled &&
//                             <h6 className="me-3 text-success small">
//                                 <i className="fas fa-check me-2"></i> Auto Category
//                             </h6>
//                         }
//                         {selectedICollection?.autoMentionsEnabled &&
//                             <h6 className="me-3 text-success text-uppercase small">
//                                 <i className="fas fa-check me-2"></i> Auto Mentions
//                             </h6>
//                         }
//                         {selectedICollection?.autoSummariseEnabled &&
//                             <h6 className="me-3 text-success text-uppercase small">
//                                 <i className="fas fa-check me-2"></i> Auto Summarise
//                             </h6>
//                         }
//                         {selectedICollection?.autoTranslateEnabled &&
//                             <h6 className="me-3 text-success text-uppercase small">
//                                 <i className="fas fa-check me-2"></i> Auto Translate
//                             </h6>
//                         }
//                         {selectedICollection?.autoEli5Enabled &&
//                             <h6 className="me-3 text-success text-uppercase small">
//                                 <i className="fas fa-check me-2"></i> Auto ElI5
//                             </h6>
//                         }

//                         {selectedICollection?.autoInterogateEnabled &&
//                             <h6 className="me-3 text-success text-uppercase small">
//                                 <i className="fas fa-check me-2"></i> Interogate
//                             </h6>
//                         }
//                     </div>

//                 </div>


//                 <div><Files /></div>
//                 <div><MyDropzone /></div>

//             </div >
//         </>
//     );
// }

// export default Collections;



import React, { useContext } from 'react';
import { CollectionContext } from '../context/DataContexts';
import MyDropzone from './MyDropzone';
import Files from './files';
import { UserAuth } from '../context/AuthContext';

function Collections() {
    const { user, logOut, userData } = UserAuth();
    const { selectedICollection } = useContext(CollectionContext);

    // Check if user status is 'blocked'
    if (userData?.status === 'blocked') {
        return (
            <div style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                height: '90vh',  // This will ensure it covers the full viewport height
                flexDirection: 'column', // Centers the text vertically and horizontally
                fontSize: '24px', // Example font size, adjust as needed
            }}>
                <div className="text-center">
                    <h1 className="mb-3">Account Locked</h1>
                    <p className="mb-3">
                        It looks like you may have reached the threshold limit for a trial account.
                        As a result, your account has been temporarily locked.
                    </p>
                    <p className="mb-3">
                        Please contact us at <a href="mailto:info@doctran.ai" className="alert-link">info@doctran.ai</a> to resolve this issue.
                    </p>
                </div>
            </div>
        );
    }

    if (!selectedICollection) {
        return (
            <div style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                height: '90vh',  // This will ensure it covers the full viewport height
                fontSize: '24px'  // Just an example, adjust as needed
            }}>
                Please select a folder from the sidebar.
            </div>
        );
    }

    // Rest of the component content...
    // ...

    return (
        <>
            <div className="">
                <div className='pt-4 position-relative mt-4'>
                    <h6 className='text-uppercase  mt-3  '>
                        <i className="fas fa-folder me-2 ml-3 "></i>
                        {selectedICollection.collectionName}


                    </h6>
                    <div className="features-list d-flex justify-content-start " style={{ top: '18px', right: '5px' }}>
                        {selectedICollection?.autoCategoryEnabled &&
                            <h6 className="me-3 text-success small">
                                <i className="fas fa-check me-2"></i> Auto Category
                            </h6>
                        }
                        {selectedICollection?.autoMentionsEnabled &&
                            <h6 className="me-3 text-success text-uppercase small">
                                <i className="fas fa-check me-2"></i> Auto Mentions
                            </h6>
                        }
                        {selectedICollection?.autoSummariseEnabled &&
                            <h6 className="me-3 text-success text-uppercase small">
                                <i className="fas fa-check me-2"></i> Auto Summarise
                            </h6>
                        }
                        {selectedICollection?.autoTranslateEnabled &&
                            <h6 className="me-3 text-success text-uppercase small">
                                <i className="fas fa-check me-2"></i> Auto Translate
                            </h6>
                        }
                        {selectedICollection?.autoEli5Enabled &&
                            <h6 className="me-3 text-success text-uppercase small">
                                <i className="fas fa-check me-2"></i> Auto ElI5
                            </h6>
                        }

                        {selectedICollection?.autoInterogateEnabled &&
                            <h6 className="me-3 text-success text-uppercase small">
                                <i className="fas fa-check me-2"></i> Interogate
                            </h6>
                        }
                    </div>

                </div>


                <div><Files /></div>
                <div><MyDropzone /></div>

            </div >
        </>
    );
}

export default Collections;
