import React, { useCallback, useContext, useState, useEffect } from 'react';
import { useDropzone } from 'react-dropzone';
import axios from 'axios';
import {
    CollectionContext,
} from '../context/DataContexts';
import { getAuth } from 'firebase/auth';
import { db } from '../firebase';
import { getFirestore, collection, addDoc } from 'firebase/firestore';

function MyDropzone() {
    const { selectedICollection } = useContext(CollectionContext);
    const [uploadNotifications, setUploadNotifications] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const MAX_FILES = 5;


    const auth = getAuth();
    const currentUser = auth.currentUser;

    useEffect(() => {
        if (uploadNotifications.length > 0) {
            const timer = setTimeout(() => {
                setUploadNotifications([]);
            }, 5000); // Clear notifications after 5 seconds

            return () => {
                clearTimeout(timer); // Cleanup timer if the component is unmounted
            };
        }
    }, [uploadNotifications]);


    const onDrop = useCallback(acceptedFiles => {
        // Filter files that do not have .pdf extension.
        const pdfFiles = acceptedFiles.filter(file => file.name.toLowerCase().endsWith('.pdf'));

        if (acceptedFiles.length > MAX_FILES) {
            setUploadNotifications(prev => [...prev, {
                fileName: "Bulk Upload",
                status: 'failed',
                message: `You can upload a maximum of ${MAX_FILES} files at once for Demo. Contact sales please.`
            }]);
            return;
        }
        const basePath = "https://doc-ai-upload.wittymushroom-7367a36d.uksouth.azurecontainerapps.io/upload/";
        const url = `${basePath}${selectedICollection.id}/`;

        // Proceed if there are valid PDF files
        if (pdfFiles.length > 0) {
            setIsLoading(true);
            // Create an asynchronous function to process files serially
            const processFiles = async () => {
                for (const file of acceptedFiles) {
                    const formData = new FormData();
                    formData.append('file', file);

                    try {
                        const response = await axios.post(url, formData);
                        const folderPath = selectedICollection.collectionName || '';
                        const utcTimestamp = Date.now();
                        const payload = {
                            author_Id: currentUser.uid,
                            collectionId: selectedICollection.id,
                            folder_path: selectedICollection.id,
                            cleaned_filename: response.data.cleaned_filename,
                            meta_content: response.data.meta_content,
                            utc_createdAt: utcTimestamp
                        };

                        const docRef = await addDoc(collection(db, "files"), payload);

                        setUploadNotifications(prev => [...prev, {
                            fileName: file.name,
                            status: 'success'
                        }]);
                    } catch (error) {
                        setUploadNotifications(prev => [...prev, {
                            fileName: file.name,
                            status: 'failed'
                        }]);
                    }
                }
                setIsLoading(false); // Set loading state to false after processing all files
            };
            processFiles(); // Call the asynchronous function
            setIsLoading(true);
        }







    }, [selectedICollection, currentUser]);



    const { getRootProps, getInputProps } = useDropzone({
        accept: '.pdf',
        multiple: true,
        onDrop: onDrop
    });

    return (
        <div className="container bg-light py-3 dropzone-container">
            <div {...getRootProps({ className: 'dropzone' })}>
                <input {...getInputProps()} />
                <p>Drag 'n' drop some (.pdf) files here, or click to select files. (max file size: 15MB) </p>
                {isLoading &&
                    <div className="loader-content">
                        <div className="loader"></div>
                        <div>Loading...</div>
                    </div>
                } {/* Loading indicator */}
            </div>
            <aside>

                <ul>
                    {uploadNotifications.map((notification, index) => (
                        <li key={index} className={`text-${notification.status === 'success' ? 'success' : 'danger'}`}>
                            {notification.fileName} - {notification.message || (notification.status === 'success' ? 'Uploaded' : 'Failed')}
                        </li>
                    ))}

                </ul>
                {uploadNotifications.length > 0 && <button className='btn btn-outline mt-2 btn-sm text-uppercase small' onClick={() => setUploadNotifications([])}>Clear</button>}
            </aside>
        </div>
    );
}

export default MyDropzone;
