import React, { useState, useEffect, useContext, useRef } from 'react';
import Modal from 'react-modal';
import {
    CollectionContext,
    MentionsListDataContext,
    CategoriesListDataContext,
    TranslateListDataContext

} from '../context/DataContexts';
import { getAuth } from 'firebase/auth';
import { db } from '../firebase';
import { getFirestore, collection, query, where, onSnapshot, orderBy, getDocs, doc, setDoc, updateDoc, addDoc, limit, deleteDoc } from 'firebase/firestore';

function Files() {
    const textInputRef = useRef(null);
    Modal.setAppElement('#root');
    const { selectedICollection } = useContext(CollectionContext);
    const { mentionsListDataContext } = useContext(MentionsListDataContext);
    const { categoriesListDataContext } = useContext(CategoriesListDataContext);
    const { translateListDataContext } = useContext(TranslateListDataContext);
    const [isSummaryModalVisible, setIsSummaryModalVisible] = useState(false);
    const [viewSummary, setViewSummary] = useState(false);


    const [isRunningState, setIsRunningState] = useState({}); // Initialize the state object




    const [isChatPanelOpen, setChatPanelOpen] = useState(false);
    const [activeChatDoc, setActiveChatDoc] = useState(null);
    const [activeChatDocType, setActiveChatDocType] = useState(null);
    const [messages, setMessages] = useState([]);

    const [isRunning, setIsRunning] = useState(false);


    const messagesEndRef = useRef(null); // Reference to auto-scroll the messages container
    const isLastMessageFromUser = messages[messages.length - 1]?.from === 'user';


    const [isModalOpen, setIsModalOpen] = useState(false);
    const [selectedSourceDocs, setSelectedSourceDocs] = useState([]);


    const handleOpenModal = (sourceDocs) => {
        setSelectedSourceDocs(sourceDocs);
        setIsModalOpen(true);
    };

    const handleCloseModal = () => {
        setIsModalOpen(false);
    };

    useEffect(() => {
        // Auto-scroll to the last message
        messagesEndRef.current?.scrollIntoView({ behavior: 'smooth' });
    }, [messages]);

    const handleCopy = (message) => {
        navigator.clipboard.writeText(message).then(() => {
            console.log('Copied to clipboard');
        });
    };


    const [files, setFiles] = useState([]);
    const auth = getAuth();
    const currentUser = auth.currentUser;

    const [selectedFiles, setSelectedFiles] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const itemsPerPage = 10; // Show 50 records per page





    useEffect(() => {
        if (activeChatDocType && activeChatDoc) {
            const messagesRef = collection(db, "chat", activeChatDoc.chatId, "messages");
            const q = query(messagesRef, orderBy("utcTimestamp", "asc"), limit(100));

            const unsubscribe = onSnapshot(q, (snapshot) => {
                let fetchedMessages = [];
                snapshot.forEach(doc => {
                    fetchedMessages.push(doc.data());
                });
                setMessages(fetchedMessages);
            });

            return () => unsubscribe(); // Cleanup listener on component unmount
        }
    }, [activeChatDocType, activeChatDoc, currentUser]);

    useEffect(() => {
        if (selectedICollection && selectedICollection.id) {
            const q = query(
                collection(db, "files"),
                where("collectionId", "==", selectedICollection.id),
                where("author_Id", "==", currentUser.uid),
            );

            const unsubscribe = onSnapshot(q, snapshot => {
                let fetchedFiles = snapshot.docs.map(doc => ({
                    id: doc.id,
                    ...doc.data()
                }));

                // Sort the files based on utc_createdAt in descending order
                fetchedFiles = fetchedFiles.sort((a, b) => b.utc_createdAt - a.utc_createdAt);

                setFiles(fetchedFiles);
            });

            return () => unsubscribe(); // Cleanup listener on component unmount
        }
    }, [selectedICollection, currentUser]);


    if (files.length === 0) {
        return (
            <div className="d-flex justify-content-center align-items-center bg-light" style={{ height: '80vh' }}>
                <span className="fs-4 text-uppercase"><i className="fa-solid fa-arrow-down"></i> Upload files</span>
            </div>
        );
    }




    // Updated useEffect




    // A function to handle common logic between Collection and File
    const handleChat = async (type, object) => {
        let chatId;

        // Check if chatId exists in the object or if it's from the selectedICollection
        if (type === "collection") {
            chatId = selectedICollection.chatId;
        } else {
            chatId = object.chatId;
        }

        // If chatId doesn't exist, then create a chat document
        if (!chatId) {
            const utcTimestamp = Date.now();
            const payload = {
                author_Id: currentUser.uid,
                utcTimestamp,
                type,
                collectionId: selectedICollection.id,
                fileId: object.cleaned_filename
            };

            const docRef = await addDoc(collection(db, "chat"), payload);
            chatId = docRef.id;

            const docToUpdate = doc(db, "files", object.id);
            await updateDoc(docToUpdate, { chatId });
        }

        setActiveChatDoc({ ...object, chatId });
        setChatPanelOpen(true);
        setActiveChatDocType(type);
    };

    // Updated handlers
    const handleCollectionChat = () => handleChat("collection", selectedICollection);
    const handleDocChat = (file) => handleChat("file", file);


    // Unchanged handleSubmitMessage
    const handleSubmitMessage = async () => {
        const questionText = textInputRef.current.value;
        const utcTimestamp = Date.now();

        if (activeChatDoc.chatId && questionText.trim()) {
            const messagesRef = collection(db, "chat", activeChatDoc.chatId, "messages");
            await addDoc(messagesRef, {
                from: 'user',
                message: questionText,
                utcTimestamp,
            });
            textInputRef.current.value = '';
        }
    };







    const handleFileSelection = (fileId, isSelected) => {
        if (isSelected) {
            setSelectedFiles(prev => [...prev, fileId]);
        } else {
            setSelectedFiles(prev => prev.filter(id => id !== fileId));
        }
    }

    const closeChatPanel = () => {
        setChatPanelOpen(false);   // Close the chat panel
        setMessages([]);           // Clear all messages
        setActiveChatDocType(null);   // Reset active document type
        setActiveChatDoc(null);       // Reset active document
        if (textInputRef.current) {
            textInputRef.current.value = "";   // Clear the text input
        }
    };


    const handleDelete = async () => {
        const db = getFirestore();

        for (const fileId of selectedFiles) {
            const fileDoc = doc(db, "files", fileId);
            await deleteDoc(fileDoc);
        }

        console.log("Selected files deleted successfully!");

    };

    const handleDownload = () => {
        // Logic to download selected files
    };

    const handlePageChange = (newPage) => {
        setCurrentPage(newPage);
    };

    const generateCompoundKey = (fileId, eventType) => `${fileId}-${eventType}`;

    const handleEventsRunButtonClick = (collectionId, file, event_type) => {



        const compoundKey = generateCompoundKey(file.id, event_type);

        // Set the running state for the specific button using the compound key
        setIsRunningState((prevIsRunningState) => ({
            ...prevIsRunningState,
            [compoundKey]: true, // Use the compound key as the key
        }));

        // Perform your event handling logic here
        // Once the event handling is complete, you can reset the running state
        setTimeout(() => {
            // Reset the running state after your event handling is done
            setIsRunningState((prevIsRunningState) => ({
                ...prevIsRunningState,
                [compoundKey]: false, // Set the running state to false
            }));
        }, 6000000); // Adjust the timeout as needed



        // Perform your run action here
        createNewEvent(collectionId, event_type, file);
    };

    // Define the showSummaryModal function here
    const showSummaryModal = (data, type) => {
        // Initialize content and heading variables
        let content = null;
        let heading = null;

        if (type === 'interrogate') {
            // Render the interrogate data
            content = data.map((item, index) => (
                <div key={index}>
                    <p>
                        <strong>Question:</strong> {item.question}
                        <br />
                        <strong>Answer:</strong> {item.answer}
                    </p>
                    <br />
                </div>
            ));
            heading = <h4>Interrogate</h4>; // Set the heading for interrogate content
        } else if (type === 'summary') {
            // Render the summary data
            content = <p>{data}</p>;
            heading = <h4>Summary</h4>; // Set the heading for summary content
        }

        // Set the content and display the modal
        setViewSummary(
            <>
                {heading}
                <hr />
                {content}
            </>
        );

        setIsSummaryModalVisible(true);
    };

    const hideSummaryModal = () => {
        // Hide the modal by setting isModalVisible to false
        setIsSummaryModalVisible(false);
    };

    const createNewEvent = async (directory, event_type, file) => {
        const utcTimestamp = Date.now();
        try {
            const eventPayload = {
                eventType: event_type,
                directory: directory,
                doc: file.cleaned_filename,
                author_Id: currentUser.uid,
                file_Id: file.id,
                timestamp: utcTimestamp
            };
            const docRefEvent = await addDoc(collection(db, "events"), eventPayload);

            console.log(event_type, "Event - Document written with ID: ", docRefEvent.id);
        } catch (error) {
            console.error("Error adding document: ", error);
        }
    };


    const visibleFiles = files.slice((currentPage - 1) * itemsPerPage, currentPage * itemsPerPage);
    const isQandAEnabled = visibleFiles.some(file => file.embeddings === 1 && file.text_extraction === 1);


    return (
        <div className="container mt-4 file-container ">
            {/* Buttons for delete and download */}
            <div className="mb-3">


                {
                    isQandAEnabled && (
                        <button className="btn btn-sm btn-outline-secondary" onClick={handleCollectionChat}>
                            Q & A (All files)
                        </button>
                    )
                }

                {/* Delete and Download buttons on the right */}
                {/* <button className="btn btn-outline-secondary btn-sm float-right ml-2" onClick={handleDownload}>Download</button> */}
                <button className="btn btn-outline-secondary btn-sm float-right" onClick={handleDelete}>Delete</button>

                {/* Clearfix to ensure proper layout */}
                <div className="clearfix"></div>
            </div>

            <div className="table-responsive file-table" style={{ overflowX: 'auto' }}>
                <table className="table table-bordered table-light  table-striped table-sm file-table">
                    <thead className="thead-dark bg-secondary ">
                        <tr>
                            <th className="text-center" style={{ width: "40px" }}>
                                <input
                                    type="checkbox"
                                    onChange={(e) => {
                                        if (e.target.checked) {
                                            setSelectedFiles(visibleFiles.map(f => f.id));
                                        } else {
                                            setSelectedFiles([]);
                                        }
                                    }}
                                    checked={selectedFiles.length === visibleFiles.length && visibleFiles.length !== 0}
                                />
                            </th>
                            <th className="text-uppercase small"  >File</th>
                            <th className="text-uppercase small text-center" >Q & A</th>
                            <th className="text-uppercase small text-center"  >Summary</th>
                            <th className="text-uppercase small text-center" >Interrogate</th>



                        </tr>
                    </thead>
                    <tbody>
                        {visibleFiles.map(file => (
                            <tr key={file.id}>
                                <td className="text-center">
                                    <input
                                        type="checkbox"
                                        checked={selectedFiles.includes(file.id)}
                                        onChange={(e) => handleFileSelection(file.id, e.target.checked)}
                                    />
                                </td>
                                <td>
                                    <small className='text-truncate'>{file.meta_content["Original Name"]} </small>
                                    {
                                        (file.embeddings === 1 && file.text_extraction === 1) ? (
                                            <span className="badge badge-pill badge-warning badge-tick ml-1">
                                                <i className="fas fa-check"></i>
                                            </span>
                                        ) : (

                                            <span className="badge badge-pill badge-warning badge-tick ml-1">
                                                <i className="fas fa-cog fa-spin"></i>
                                            </span>

                                        )
                                    }




                                    {/* {file.embeddings === 1 && file.text_extraction === 1 && (
                                        <span className="badge badge-pill badge-info">
                                            <i className="fas fa-check"></i> embeddings
                                        </span>
                                    )} */}
                                </td>
                                <td className="text-center align-middle">

                                    {file.embeddings === 1 && file.text_extraction === 1 && (
                                        <button className="btn btn-xs btn-outline-secondary" onClick={() => handleDocChat(file)}> Q & A</button>
                                    )}

                                </td>
                                <td className="text-center align-middle">

                                    {file.embeddings === 1 && file.text_extraction === 1 && (
                                        <div>
                                            {file.summary ? (
                                                // Display the "View" button
                                                <button className="btn btn-xs btn-outline-secondary" onClick={() => showSummaryModal(file.summary, 'summary')}>View</button>
                                            ) : (
                                                // Display the "Run" button or loading animation
                                                <button
                                                    className="btn btn-xs btn-outline-secondary"
                                                    disabled={isRunningState[generateCompoundKey(file.id, 'summary')]}
                                                    onClick={() => handleEventsRunButtonClick(selectedICollection.id, file, 'summary')}
                                                >
                                                    {isRunningState[generateCompoundKey(file.id, 'summary')] ? "Running..." : "Run"}
                                                </button>
                                            )}
                                        </div>
                                    )}






                                </td>
                                <td className="text-center align-middle">

                                    {file.embeddings === 1 && file.text_extraction === 1 && (
                                        <div>
                                            {selectedICollection?.autoInterogateEnabled ?
                                                <span className="yes-tag">yes</span> :


                                                <div>
                                                    {file.interrogate ? (
                                                        // Display the "View" button
                                                        <button className="btn btn-xs btn-outline-secondary" onClick={() => showSummaryModal(file.interrogate, 'interrogate')}>View</button>
                                                    ) : (
                                                        // Display the "Run" button or loading animation
                                                        <button
                                                            className="btn btn-xs btn-outline-secondary"
                                                            disabled={isRunningState[generateCompoundKey(file.id, 'interrogate')]}
                                                            onClick={() => handleEventsRunButtonClick(selectedICollection.id, file, 'interrogate')}
                                                        >
                                                            {isRunningState[generateCompoundKey(file.id, 'interrogate')] ? "Running..." : "Run"}
                                                        </button>
                                                    )}
                                                </div>




                                            }
                                        </div>
                                    )}

                                </td>







                            </tr>
                        ))}
                    </tbody>
                </table>
            </div >

            {/* Pagination Logic (simple example, can be improved with a pagination component) */}
            <div className="mt-3">
                {[...Array(Math.ceil(files.length / itemsPerPage)).keys()].map(num => (
                    <button
                        key={num}
                        className={`btn btn-xs ${num + 1 === currentPage ? 'btn-link' : 'btn-light'} mr-2`}
                        onClick={() => handlePageChange(num + 1)}
                    >
                        {num + 1}
                    </button>
                ))}
            </div>


            <div>
                {/* Your component and button to call handleDocChat here... */}

                {isChatPanelOpen && (
                    <div
                        className="position-fixed h-100 d-flex flex-column"
                        style={{
                            top: 0,
                            right: 0,
                            width: '75%',
                            backgroundColor: '#f8f9fa',
                            zIndex: 998,
                            overflowY: 'auto',
                            boxShadow: '-2px 0 5px rgba(0, 0, 0, 0.3)'
                        }}
                    >
                        {/* Top: Document Details */}


                        <div className="p-3 bg-light border-bottom">
                            <h5 className="mt-4 pt-4">

                                <i className="fas fa-folder me-2"></i>


                                {activeChatDocType === 'file' ? selectedICollection.collectionName + ' -- ' + activeChatDoc.meta_content["Original Name"] :
                                    (activeChatDocType === 'collection' ? selectedICollection.collectionName : '')
                                }
                            </h5>
                            {/* Other document details here */}
                        </div>


                        {/* Middle: Chat Messages */}
                        {/* Middle: Chat Messages */}
                        <div className="flex-grow-1 p-3 overflow-auto">
                            {messages.map((message, index) => (
                                <div key={index} className="mb-3">
                                    <strong>{message.from}: </strong><small> {new Date(message.utcTimestamp).toLocaleString(undefined, {
                                        year: 'numeric',
                                        month: 'short',
                                        day: 'numeric',
                                        hour: 'numeric',
                                        minute: 'numeric'
                                    })}</small>
                                    {message.from === 'system' && (
                                        <React.Fragment>

                                            <span
                                                onClick={() => handleCopy(message.message)}
                                                style={{ cursor: 'pointer', float: 'right', marginRight: '10px' }}
                                            >
                                                <i className="fa-regular fa-copy ml-3"></i>
                                            </span>

                                            <span
                                                onClick={() => handleOpenModal(message.source_documents)}
                                                style={{ cursor: 'pointer', float: 'right' }}
                                            >
                                                <small>SOURCE</small>
                                            </span>


                                        </React.Fragment>
                                    )}
                                    <div className={`border rounded p-2 ${message.from === 'system' ? 'bg-secondary text-white' : ''}`}>


                                        {message.message}


                                    </div>
                                </div>
                            ))}
                            <div ref={messagesEndRef} />
                        </div>




                        {/* Bottom: Input Area */}

                        {/* Bottom: Input Area */}
                        <div className="p-3 bg-light border-top">
                            <textarea
                                ref={textInputRef}
                                className="form-control mb-2"
                                placeholder="Type your question..."
                                maxLength={300}
                                pattern="[^!@#$%^&*()_+=]+"
                            />

                            <div className="d-flex justify-content-between">
                                <button
                                    className="btn btn-light btn-xs"
                                    onClick={closeChatPanel}
                                >
                                    Close
                                </button>

                                <button className="btn btn-primary" disabled={isLastMessageFromUser} onClick={handleSubmitMessage}>
                                    {isLastMessageFromUser ? 'Loading...' : 'Submit'}
                                </button>
                            </div>
                        </div>

                        <button
                            className="btn btn-light btn-xs m-2 float-right"
                            style={{ position: 'absolute', top: '10px', right: '10px' }}
                            onClick={closeChatPanel}
                        >
                            Close
                        </button>
                    </div>
                )}
            </div>




            <Modal
                isOpen={isSummaryModalVisible}
                onRequestClose={hideSummaryModal}
                contentLabel="Summary"

            >
                <div className="modal-content">
                    <button className="btn position-absolute top-0 end-0" onClick={hideSummaryModal}>Close</button>

                    {/* Your modal content */}

                    {viewSummary}


                </div>
            </Modal>


            <Modal
                isOpen={isModalOpen}
                onRequestClose={handleCloseModal}
                contentLabel="Source Documents"

            >
                <button className="btn position-absolute top-0 end-0" onClick={handleCloseModal}>Close</button>

                <h2>Source Documents</h2>
                <hr />
                {/* {selectedSourceDocs.map((doc, index) => (
                    <div key={index}>
                        <h5>Document {index + 1}</h5>
                        <p>{doc.page_content}</p>
                        {doc.metadata && doc.metadata.source && (
                            <p><strong>Source:</strong> {doc.metadata.source}</p>
                        )}
                    </div>
                ))} */}



                {selectedSourceDocs.map((doc, index) => {
                    // Assuming 'doc.metadata.source' exists and has the format 'temp_download/filename.pdf'
                    const cleanedSource = doc.metadata.source.replace('temp_download/', '');

                    // Find the corresponding file object in the 'files' array
                    const matchedFile = files.find(file => file.cleaned_filename === cleanedSource);

                    // Determine what to display based on whether matchedFile is found
                    let displayContent;
                    if (matchedFile) {
                        // Access 'Original Name' from the 'meta_content' of the matched file
                        // Make sure to use the exact key string, including spaces and case
                        const originalName = matchedFile.meta_content["Original Name"];
                        displayContent = <p><strong>Source:</strong> {originalName}</p>;
                    } else {
                        // Display the cleanedSource and a note if the file is not found
                        displayContent = (
                            <>
                                <p><strong>Source:</strong> {cleanedSource}</p>
                                <p>Note: File may be deleted from database. </p>
                            </>
                        );
                    }

                    return (
                        <div key={index}>
                            <h5>Document {index + 1}</h5>
                            <p>{doc.page_content}</p>
                            {doc.metadata && doc.metadata.source && displayContent}
                        </div>
                    );
                })}







                <button className="btn btn-lg float-right" onClick={handleCloseModal}>Close</button>

            </Modal>





        </div>
    );
}

export default Files;
