import React, { useEffect, useState, useContext } from 'react'; // <-- Import useState for local component state
import { getAuth, signOut } from 'firebase/auth';
import { db } from '../firebase';
import { getFirestore, collection, getDocs, query, where, addDoc, doc, deleteDoc, orderBy, updateDoc } from 'firebase/firestore';
import {
    CollectionContext,
    MentionsListDataContext,
    CategoriesListDataContext,
    TranslateListDataContext

} from '../context/DataContexts';

import { UserAuth } from '../context/AuthContext';

function Sidebar({ currentUser }) {
    const { user, logOut, userData } = UserAuth();
    const [isCreating, setIsCreating] = useState(false); // Track if the user is in "create" mode
    const [collectionName, setCollectionName] = useState(""); // Store the name of the new collection
    const [collectionNameError, setCollectionNameError] = useState('');
    const [errorMessage, setErrorMessage] = useState(null);


    const { selectedICollection, setSelectedICollection } = useContext(CollectionContext);
    const { mentionsListDataContext, setMentionsListDataContext } = useContext(MentionsListDataContext);
    const { categoriesListDataContext, setCategoriesListDataContext } = useContext(CategoriesListDataContext);
    const { translateListDataContext, setTranslateListDataContext } = useContext(TranslateListDataContext);


    const authInstance = getAuth();

    const resetForm = () => {
        setDescription('');

        setPersonDescription('');
        setDescriptionValid(false);
        setFullNameValid(false);
        setPersonDescriptionValid(false);
    }

    const resetCategoryForm = () => {

        setCategoryDescription('');
        setCategoryKeywords('');  // Reset the keywords as well
        setCategoryDescriptionValid(false);
        setCategoryKeywordsValid(false);  // Reset the keywords validation

    }

    const [collections, setCollections] = useState([]);

    const [translate, setTranslate] = useState([]);
    const [autoTranslateEnabled, setAutoTranslateEnabled] = useState(false);


    const [autoSummariseEnabled, setAutoSummariseEnabled] = useState(false); // default to false, adjust as needed
    const [autoEli5Enabled, setAutoEli5Enabled] = useState(false); // default to false, adjust as needed

    const [autoInterogateEnabled, setAutoInterogateEnabled] = useState(false); // default to false, adjust as needed

    const [autoCategoryEnabled, setAutoCategoryEnabled] = useState(false);
    const [categories, setCategories] = useState([]);
    const [selectedCategory, setSelectedCategory] = useState('');

    const [selectedMention, setSelectedMention] = useState('');
    const [selectedTranslate, setSelectedTranslate] = useState('');


    const [categoryDescription, setCategoryDescription] = useState('');

    const [categoryDescriptionValid, setCategoryDescriptionValid] = useState(false);

    const [categoryKeywords, setCategoryKeywords] = useState('');
    const [categoryKeywordsValid, setCategoryKeywordsValid] = useState(false);




    const [autoMentionsEnabled, setAutoMentionsEnabled] = useState(false);
    const [mentions, setMentions] = useState([]);

    const [showModal, setShowModal] = useState(false);
    const [description, setDescription] = useState('');
    const [fullName, setFullName] = useState('');
    const [personDescription, setPersonDescription] = useState('');



    const [descriptionValid, setDescriptionValid] = useState(false);
    const [fullNameValid, setFullNameValid] = useState(false);
    const [personDescriptionValid, setPersonDescriptionValid] = useState(false);

    const [showCategoryModal, setShowCategoryModal] = useState(false);

    const [refreshData, setRefreshData] = useState(false);

    useEffect(() => {
        if (!currentUser) return; // Exit if user is not authenticated

        const fetchData = async () => {
            const db = getFirestore();
            const mentionsCol = collection(db, "mentions");
            const mentionsQuery = query(mentionsCol, where("author_Id", "==", currentUser.uid), orderBy("utc_createdAt", "desc"));
            const mentionsSnapshot = await getDocs(mentionsQuery);

            const mentionsList = [];
            mentionsSnapshot.forEach(doc => {
                mentionsList.push({ id: doc.id, ...doc.data() });
            });

            setMentions(mentionsList);
            setMentionsListDataContext(mentionsList);

            // Fetch categories
            const categoriesCol = collection(db, "categories");
            const categoriesQuery = query(categoriesCol, where("author_Id", "==", currentUser.uid), orderBy("utc_createdAt", "desc"));
            const categoriesSnapshot = await getDocs(categoriesQuery);

            const categoriesList = [];
            categoriesSnapshot.forEach(doc => {
                categoriesList.push({ id: doc.id, ...doc.data() });
            });

            setCategories(categoriesList);
            setCategoriesListDataContext(categoriesList);


            // Fetch translate
            const translateCol = collection(db, "translate");

            const translateSnapshot = await getDocs(translateCol);

            const translateList = [];
            translateSnapshot.forEach(doc => {
                translateList.push({ id: doc.id, ...doc.data() }); // Fixed here
            });

            setTranslate(translateList);
            setTranslateListDataContext(translateList);


            // Fetch collections
            const collectionCol = collection(db, "collections");
            const collectionQuery = query(collectionCol, where("author_Id", "==", currentUser.uid), orderBy("utc_createdAt", "desc"));
            const collectionSnapshot = await getDocs(collectionQuery);

            const collectionList = [];
            collectionSnapshot.forEach(doc => {
                collectionList.push({ id: doc.id, ...doc.data() }); // Fixed here
            });

            setCollections(collectionList, refreshData);


        };

        fetchData();
    }, [currentUser]);

    const handleSignOut = () => {
        signOut(authInstance).then(() => {
            console.log("User signed out!");
        }).catch((error) => {
            console.error("Error signing out:", error);
        });
    };

    const handleCreate = async () => {
        // Clear previous error messages
        setErrorMessage(null);

        // First, validate the input
        if (!collectionName) {
            setCollectionNameError('Please enter a folder name.');
            // setErrorMessage('Please enter a folder name.');
            return;
        } else if (collectionNameError) {
            setErrorMessage(collectionNameError);
            // Don't proceed if there's an error
            return;
        }

        if (autoSummariseEnabled) {
            setErrorMessage("For access to automated features, please contact us at info@doctran.ai to inquire about our premium accounts.");
            return;
        }
        if (autoTranslateEnabled) {
            setErrorMessage("For access to automated features, please contact us at info@doctran.ai to inquire about our premium accounts.");
            return;
        }

        if (autoInterogateEnabled) {
            setErrorMessage("For access to automated features, please contact us at info@doctran.ai to inquire about our premium accounts.");

            return;
        }

        if (autoCategoryEnabled && !selectedCategory) {
            setErrorMessage("For access to automated features, please contact us at info@doctran.ai to inquire about our premium accounts.");

            // setErrorMessage('Please select a category.');
            return;
        }
        if (autoMentionsEnabled && !selectedMention) {
            setErrorMessage("For access to automated features, please contact us at info@doctran.ai to inquire about our premium accounts.");

            // setErrorMessage('Please select a mention.');
            return;
        }
        if (autoTranslateEnabled && !selectedTranslate) {
            setErrorMessage("For access to automated features, please contact us at info@doctran.ai to inquire about our premium accounts.");

            // setErrorMessage('Please select a language.');
            return;
        }
        const utcTimestamp = Date.now();

        // Logic to create the collection
        const payload = {
            collectionName: collectionName,
            autoEli5Enabled: autoEli5Enabled,
            autoInterogateEnabled: autoInterogateEnabled,
            autoCategoryEnabled: autoCategoryEnabled,
            autoMentionsEnabled: autoMentionsEnabled,
            autoSummariseEnabled: autoSummariseEnabled,
            autoTranslateEnabled: autoTranslateEnabled,
            selectedCategory: selectedCategory,
            selectedTranslate: selectedTranslate,
            selectedMention: selectedMention,
            author_Id: currentUser.uid,
            utc_createdAt: utcTimestamp // added this line
        };


        try {
            const docRef = await addDoc(collection(db, "collections"), payload);
            console.log("Document written with ID: ", docRef.id);

            const chatPayload = {
                author_Id: currentUser.uid,
                collectionId: docRef.id,
                type: "collection",
                utcTimestamp,
            };

            const docRefChat = await addDoc(collection(db, "chat"), chatPayload);
            let chatId = docRefChat.id;  // Declare chatId here

            // update collection with chat Id 
            const docToUpdate = doc(db, "collections", docRef.id);
            await updateDoc(docToUpdate, { chatId });

            // Update the local collections list
            setCollections(prevCollections => [
                ...prevCollections,
                {
                    id: docRef.id,
                    ...payload,
                    chatId
                }
            ]);

            setRefreshData(prev => !prev);

        } catch (error) {
            console.error("Error adding document: ", error);
        }






        // Implement further logic to send the payload or handle the data
        // ...
        setCollectionName("");
        setAutoSummariseEnabled(false)
        setAutoCategoryEnabled(false)
        setAutoEli5Enabled(false)
        setAutoInterogateEnabled(false)
        setAutoMentionsEnabled(false)
        setAutoTranslateEnabled(false)

        setIsCreating(false);
    };



    const handleCancel = () => {
        setCollectionName(""); // Reset the collection name
        setAutoSummariseEnabled(false)
        setAutoCategoryEnabled(false)
        setAutoEli5Enabled(false)
        setAutoInterogateEnabled(false)
        setAutoMentionsEnabled(false)
        setAutoTranslateEnabled(false)
        setErrorMessage("")
        setCollectionNameError("")

        setIsCreating(false); // Go back to the initial state
    };

    const handleCategorySave = async () => {
        const keywordsEnum = categoryKeywords.split(',').map(keyword => keyword.trim());
        console.log("Saving Category:", { categoryDescription, keywords: keywordsEnum });
        const utcTimestamp = Date.now();

        const payload = {
            name: "category",
            description: categoryDescription,
            type: "string",
            enum: keywordsEnum,
            required: true,
            author_Id: currentUser.uid,  // assuming you have access to the current user's uid
            utc_createdAt: utcTimestamp // added this line

        };

        try {
            const docRef = await addDoc(collection(db, "categories"), payload);
            console.log("Document written with ID: ", docRef.id);

            // Update the local mentions list
            setCategories(prevCategories => [...prevCategories, { id: docRef.id, ...payload }]);

            setShowCategoryModal(false);
            resetCategoryForm();
        } catch (error) {
            console.error("Error adding document: ", error);
        }


    };




    const handleDeleteCollection = async (collection, e) => {
        e.stopPropagation();

        // Ask the user for confirmation before deleting
        const userConfirmed = window.confirm("Are you sure you want to delete this collection? All related files will be deleted.");
        if (!userConfirmed) {
            return;  // Exit the function if the user cancels the delete operation
        }

        const db = getFirestore();
        const documentRef = doc(db, 'collections', collection.id);  // Using collection.id

        try {
            await deleteDoc(documentRef);
            console.log("Document successfully deleted!");

            // Update the UI to remove the deleted collection
            setCollections(prevCollections => prevCollections.filter(item => item.id !== collection.id));

            // If selectedICollection matches the deleted item's ID, reset it
            if (selectedICollection === collection) {
                setSelectedICollection(null);  // or use an empty string ('') depending on your logic
            }

        } catch (error) {
            console.error("Error removing document: ", error);
        }
    };







    const handleSave = async () => {
        if (!descriptionValid || !fullNameValid || !personDescriptionValid) {
            console.warn("Validation failed. Not saving.");
            return;
        }
        const utcTimestamp = Date.now();

        const payload = {
            name: "mentions",
            description: description,
            type: "array",
            items: {
                name: fullName,
                description: personDescription,
                type: "string"
            },
            required: true,
            author_Id: currentUser.uid,
            utc_createdAt: utcTimestamp // added this line  // assuming you have access to the current user's uid
        };

        try {
            const docRef = await addDoc(collection(db, "mentions"), payload);
            console.log("Document written with ID: ", docRef.id);

            // Update the local mentions list
            setMentions(prevMentions => [...prevMentions, { id: docRef.id, ...payload }]);

            resetForm();
            // Close the modal
            setShowModal(false);
        } catch (error) {
            console.error("Error adding document: ", error);
        }
    };



    if (!currentUser) {
        return null;
    }

    return (


        <div className="d-flex flex-column text-white bg-secondary" style={{ height: "100vh" }}>
            {!isCreating ? (
                <div className='p-1 text-center' style={{ marginTop: 70 }}>
                    <button
                        onClick={() => setIsCreating(true)}
                        className="btn btn-outline-light btn-sm"
                        disabled={userData?.status === 'blocked'} // Button is disabled if status is 'blocked'
                    >
                        <i className="fas fa-plus me-2"></i>
                        New Folder
                    </button>
                </div>

            ) : (
                <>
                    <div className='p-2' style={{ marginTop: 60 }}>
                        <input
                            type="text"
                            value={collectionName}
                            onChange={(e) => {
                                const value = e.target.value.toUpperCase();  // Convert the value to uppercase
                                setCollectionName(value);

                                if (/[^a-zA-Z0-9-]/g.test(value)) {  // Regex allowing only letters, numbers, and hyphens, and NOT spaces
                                    setCollectionNameError('Collection name can only contain letters, numbers, and hyphens.');
                                } else if (value.length < 5) {
                                    setCollectionNameError('Collection name must be at least 5 characters long.');
                                } else if (value.length > 20) {
                                    setCollectionNameError('Collection name must be no more than 50 characters long.');
                                } else {
                                    setCollectionNameError(''); // Clear the error message
                                }
                            }}
                            placeholder="Folder Name"
                            className="form-control"
                            maxLength="50"  // set the maximum allowed characters to 50
                        />



                        <small className="form-text text-warning">
                            {collectionNameError}
                        </small>



                        <div className="form-check form-switch ml-4" style={{ paddingLeft: 20 }}>
                            <input className="form-check-input" type="checkbox" id="autoSummarise" />
                            <input
                                className="form-check-input"
                                type="checkbox"
                                id="autoSummarise"
                                checked={autoSummariseEnabled}
                                onChange={() => setAutoSummariseEnabled(!autoSummariseEnabled)}
                            />
                            <label className="form-check-label text-uppercase small" htmlFor="autoSummarise">Auto Summarise</label>
                        </div>

                        <div className="form-check form-switch ml-4" style={{ paddingLeft: 20 }}>
                            <input
                                className="form-check-input"
                                type="checkbox"
                                id="autoMentions"
                                checked={autoMentionsEnabled}
                                onChange={() => setAutoMentionsEnabled(!autoMentionsEnabled)}
                            />
                            <label className="form-check-label text-uppercase small" htmlFor="autoMentions">Auto Mentions</label>

                            {autoMentionsEnabled && (
                                <>
                                    <select className="form-select form-select-sm mt-2" value={selectedMention} onChange={(e) => setSelectedMention(e.target.value)}>
                                        <option value="" disabled>Please Select</option> {/* Default option */}
                                        {mentions.map(mention => (
                                            <option key={mention.id} value={mention.id}>
                                                {mention.description} {/* Adjust based on the structure of your mention objects */}
                                            </option>
                                        ))}
                                    </select>

                                    <button className="btn btn-primary mt-2 btn-xs text-uppercase small" onClick={() => { }} >
                                        <i className="fas fa-plus me-2"></i>New Mention
                                    </button>
                                </>
                            )}

                        </div>

                        <div className="form-check form-switch ml-4" style={{ paddingLeft: 20 }}>
                            <input
                                className="form-check-input"
                                type="checkbox"
                                id="autoCategory"
                                checked={autoCategoryEnabled}
                                onChange={() => setAutoCategoryEnabled(!autoCategoryEnabled)}
                            />
                            <label className="form-check-label text-uppercase small" htmlFor="autoCategory">Auto Category</label>

                            {autoCategoryEnabled && (
                                <>
                                    <select className="form-select form-select-sm mt-2" value={selectedCategory} onChange={(e) => setSelectedCategory(e.target.value)}>
                                        <option value="" disabled>Please Select</option> {/* Default option */}
                                        {categories.map(category => (
                                            <option key={category.id} value={category.id}>
                                                {category.description} {/* Adjust based on the structure of your category objects */}
                                            </option>
                                        ))}
                                    </select>

                                    <button className="btn btn-primary mt-2 btn-xs text-uppercase small" onClick={() => { }}>
                                        <i className="fas fa-plus me-2"></i>New Category
                                    </button>
                                </>
                            )}

                        </div>

                        <div className="form-check form-switch ml-4" style={{ paddingLeft: 20 }}>
                            <input
                                className="form-check-input"
                                type="checkbox"
                                id="autoTranslate"
                                checked={autoTranslateEnabled}
                                onChange={() => setAutoTranslateEnabled(prevState => !prevState)}
                            />
                            <label className="form-check-label text-uppercase small" htmlFor="autoTranslate">Auto Translate</label>

                            {autoTranslateEnabled && (
                                <>
                                    <select className="form-select form-select-sm mt-2" value={selectedTranslate} onChange={(e) => setSelectedTranslate(e.target.value)}>
                                        <option value="" disabled>Please Select</option> {/* Default option */}
                                        {translate.map(translateItem => (
                                            <option key={translateItem.id} value={translateItem.id}>
                                                {translateItem.language} {/* Adjust based on the structure of your translate objects */}
                                            </option>
                                        ))}
                                    </select>
                                </>
                            )}

                        </div>


                        <div className="form-check form-switch ml-4" style={{ paddingLeft: 20 }}>

                            <input
                                className="form-check-input"
                                type="checkbox"
                                id="interogate"
                                checked={autoInterogateEnabled}
                                onChange={() => setAutoInterogateEnabled(!autoInterogateEnabled)}
                            />
                            <label className="form-check-label text-uppercase small" htmlFor="interogate">Auto Interogate</label>
                        </div>
                    </div>




                    <small className="form-text text-warning text-center">
                        {errorMessage && <p className="text-warning p-2">{errorMessage}</p>}
                    </small>




                    <div className="d-flex justify-content-between mt-2 p-1">

                        <button className="btn btn-outline-light btn-sm text-uppercase" onClick={handleCancel}>Cancel</button>

                        <button className="btn btn-outline-light btn-sm text-uppercase text-truncate" onClick={handleCreate} >Create Folder</button>

                    </div>


                    {showCategoryModal && (
                        <div className="modal show d-block" tabIndex="-1" role="dialog" onClick={() => {
                            setShowCategoryModal(false);
                            resetCategoryForm();
                        }}>
                            <div className="modal-dialog" role="document" onClick={(e) => e.stopPropagation()}>
                                <div className="modal-content">
                                    <div className="modal-header">
                                        <h5 className="modal-title">New Category</h5>
                                        <button type="button" className="btn-close" aria-label="Close" onClick={() => {
                                            setShowCategoryModal(false);
                                            resetCategoryForm();
                                        }}></button>
                                    </div>
                                    <div className="modal-body">

                                        <input
                                            type="text"
                                            placeholder="Category Description"
                                            className="form-control mb-1"
                                            value={categoryDescription}
                                            onChange={(e) => {
                                                const val = e.target.value;
                                                setCategoryDescription(val);
                                                setCategoryDescriptionValid(val.split(' ').length >= 5 && !/[^a-zA-Z0-9 ]/g.test(val));
                                            }}
                                            required
                                        />
                                        <small className="form-text text-muted mb-3">{!categoryDescriptionValid && "Category Description must be at least 5 words without special characters.(example: What type of experience level this doc show.)"}</small>


                                        <input
                                            type="text"
                                            placeholder="Category Keywords (comma separated)"
                                            className="form-control mb-1"
                                            value={categoryKeywords}
                                            onChange={(e) => {
                                                const val = e.target.value;
                                                setCategoryKeywords(val);
                                                setCategoryKeywordsValid(!/[^a-zA-Z0-9, ]/g.test(val));
                                            }}
                                            required
                                        />
                                        <small className="form-text text-muted mb-3">{!categoryKeywordsValid && "Keywords must not contain special characters except commas.(example:senior, junior,student, other)"}</small>



                                    </div>
                                    <div className="modal-footer modal-footer justify-content-between">
                                        <button type="button" className="btn btn-secondary " onClick={() => {
                                            setShowCategoryModal(false);
                                            resetCategoryForm();
                                        }}>Cancel</button>
                                        <button type="button" className="btn btn-primary" onClick={handleCategorySave} disabled={!categoryKeywordsValid || !categoryDescriptionValid}>Save</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    )}



                    {showModal && (
                        <div className="modal show d-block" tabIndex="-1" role="dialog" onClick={() => {
                            setShowModal(false);
                            resetForm();
                        }}>
                            <div className="modal-dialog" role="document" onClick={(e) => e.stopPropagation()}>
                                <div className="modal-content">
                                    <div className="modal-header">
                                        <h5 className="modal-title">New Mention</h5>
                                        <button type="button" className="btn-close" aria-label="Close" onClick={() => {
                                            setShowModal(false);
                                            resetForm();
                                        }}></button>
                                    </div>
                                    <div className="modal-body">
                                        <input
                                            type="text"
                                            placeholder="Property Description"
                                            className="form-control mb-1"
                                            value={description}
                                            onChange={(e) => {
                                                const val = e.target.value;
                                                setDescription(val);
                                                setDescriptionValid(val.split(' ').length >= 5 && !/[^a-zA-Z0-9 ]/g.test(val));
                                            }}
                                            required
                                        />
                                        <small className="form-text text-muted mb-3">{!descriptionValid && "Description must be at least 5 words without special characters.(example : A list of all people mentioned in this document.)"}</small>

                                        <input
                                            type="text"
                                            placeholder="Item Property Name"
                                            className="form-control mb-1"
                                            value={fullName}
                                            onChange={(e) => {
                                                const val = e.target.value.toLowerCase();
                                                setFullName(val);
                                                setFullNameValid(val.length >= 5 && !/[^a-z]/g.test(val));
                                            }}
                                            required
                                        />
                                        <small className="form-text text-muted mb-3">{!fullNameValid && "Item Property Name must be at least 5 lowercase characters without spaces or special characters.(example: fullname)"}</small>

                                        <input
                                            type="text"
                                            placeholder="Item Description"
                                            className="form-control mb-1"
                                            value={personDescription}
                                            onChange={(e) => {
                                                const val = e.target.value;
                                                setPersonDescription(val);
                                                setPersonDescriptionValid(val.split(' ').length >= 5 && !/[^a-zA-Z0-9 ]/g.test(val));
                                            }}
                                            required
                                        />
                                        <small className="form-text text-muted mb-3">{!personDescriptionValid && "Item Description must be at least 5 words without special characters.(example: The full name of the person mentioned.)"}</small>
                                    </div>
                                    <div className="modal-footer modal-footer justify-content-between">
                                        <button type="button" className="btn btn-secondary" onClick={() => {
                                            setShowModal(false);
                                            resetForm();
                                        }}>Cancel</button>
                                        <button type="button" className="btn btn-primary" onClick={handleSave} disabled={!descriptionValid || !fullNameValid || !personDescriptionValid}>Save</button>

                                    </div>
                                </div>
                            </div>
                        </div>
                    )}

                </>


            )
            }
            <hr />
            <div className="sidebar list-group ounded-0" style={{ maxHeight: '500px', overflowY: 'auto', border: 0, borderRadius: 0, }}>
                {collections.map(collection => (


                    <button
                        key={collection.id}
                        className={`list-group-item list-group-item-action text-uppercase small rounded-0 ${selectedICollection === collection ? 'active rounded-0' : ''}`}
                        onClick={() => setSelectedICollection(collection)}
                    >
                        <div className="button-flex-container rounded-0">
                            <i
                                className="fas fa-folder icon-left mr-2"

                            ></i>
                            {collection.collectionName} {/* or whatever field you have for the name */}
                            <i style={{ color: 'darkgrey', }}
                                className="fas fa-trash icon-right "
                                onClick={(e) => handleDeleteCollection(collection, e)}
                            ></i>

                        </div>
                    </button>


                ))}
            </div>
            <hr />




            <div className="  mt-auto btn-sm">

                {/* <button className="btn btn-outline-light my-2 my-sm-0 btn-sm" onClick={() => setShowModal(true)}>
                    <i className="fas fa-plus me-2"></i>Mention
                </button>
                <button className="btn btn-outline-light my-2 my-sm-0 btn-sm" onClick={() => setShowCategoryModal(true)}>
                    <i className="fas fa-plus me-2"></i>Category
                </button> */}


                <div>
                    {/* Your sidebar JSX goes here */}

                    {/* Displaying status */}
                    <p className='p-2'>
                        Account :
                        <span
                            style={{
                                color: userData?.status === 'blocked' ? 'red' : 'white',
                                textTransform: userData?.status === 'blocked' ? 'uppercase' : 'none'
                            }}
                        >
                            {userData?.status || ' ACTIVE'}
                        </span>
                        <br />

                        Uploads :
                        {userData?.totalUploadSize ? (
                            <span> {(userData.totalUploadSize / (1024 * 1024)).toFixed(2)} MB</span>
                        ) : (
                            <span> 0 MB</span>
                        )}
                    </p>



                </div>

                <div className='text-center mb-4'><small> <i
                    className="fas fa-envelope icon-right mr-1 "
                    onClick={(e) => handleDeleteCollection(collection, e)}
                ></i> info@doctran.ai</small></div>

                {/* <div className='text-center mb-4'> <button className="btn btn-outline-light my-2 my-sm-0 btn-sm mb-2" onClick={handleSignOut}><i className="fa fa-sign-out"></i> Sign Out</button></div> */}

            </div>

        </div >


    );
}

export default Sidebar;
